import axioss from "axios";

// export const API_URL = "http://15.206.61.14/api/v1/"; //live/
// export const API_URL = "http://192.168.3.217:4001/api/v1/"; //abdullah/
// export const API_URL = "http://15.207.183.7:9000/api/v1"; //Live/
// export const API_URL = "http://192.168.0.165:9000/api/v1"; //uzair/
// export const API_URL = "http://10.3.1.206:5001/api/v1"; //uzair/
// export const API_URL = "http://10.3.100.117:9000/api/v1"; //rimsha/
// export const API_URL = "http://10.3.1.167:5000/api/v1"; //Hassan Inayat/

// export const API_URL = "https://admin.beeksi.com/api/v1"; //Live/
// export const API_URL = "https://production.beeksi.com/api/v1"; //Live/
export const API_URL = "https://staging.beeksi.com/api/v1"; //Live/

const axios = axioss.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest",
    "ngrok-skip-browser-warning": true,
  },
});

export default axios;
